import request from '@/utils/request'


// 查询平台公告列表
export function listNotice(query) {
  return request({
    url: '/platform/notice/list',
    method: 'get',
    params: query
  })
}

// 查询平台公告分页
export function pageNotice(query) {
  return request({
    url: '/platform/notice/page',
    method: 'get',
    params: query
  })
}

// 查询平台公告详细
export function getNotice(data) {
  return request({
    url: '/platform/notice/detail',
    method: 'get',
    params: data
  })
}

// 新增平台公告
export function addNotice(data) {
  return request({
    url: '/platform/notice/add',
    method: 'post',
    data: data
  })
}

// 修改平台公告
export function updateNotice(data) {
  return request({
    url: '/platform/notice/edit',
    method: 'post',
    data: data
  })
}

// 删除平台公告
export function delNotice(data) {
  return request({
    url: '/platform/notice/delete',
    method: 'post',
    data: data
  })
}
