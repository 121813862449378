<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入内容"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="1">普通公告</a-radio>
          <a-radio :value="2">新人公告</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="副标题" prop="subTitle" >
        <a-input v-model="form.subTitle" placeholder="请输入内容"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="图片路径" prop="image" >
        <file-upload type="image" :defaultList="form.imageArr" :count="1"
                     @input="getImg($event, 'image')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getNotice, addNotice, updateNotice } from '@/api/platform/notice'
import Editor from '@/components/Editor'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        title: null,
        type:null,
        subTitle: null,
        image: null,
        imageArr:[],
        imageCount:1,
        content: null,
        createTime: null,
        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        subTitle: [
          { required: true, message: '副标题不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        type:null,
        subTitle: null,
        image: null,
        imageArr:[],
        imageCount:1,
        content: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getNotice({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        let that = this;
        this.form.imageArr = []
        this.form.imageArr.push({
          uid: guid(8, 10),
          name: that.form.image,
          status: 'done',
          halfUrl: that.form.image,
          url: that.form.image,
          path: that.form.image
        })
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateNotice(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '修改成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNotice(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '新增成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
